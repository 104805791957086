<div class="c4p-form-input-{{ showLabelHorizontal ? 'full-width' : 'small' }}">
  <div
    style="flex-direction: row"
    [ngClass]="showLabelHorizontal ? 'horiz-label' : 'row'"
  >
    <label class="mat-select-placeholder" *ngIf="!!placeholder"
      >{{ (placeholder | translate) + (required ? ' *' : '') }}
    </label>
    <mat-radio-group
      class="c4p-radio-group"
      [style.flex-direction]="direction"
      [formControl]="valueControl"
      [required]="required"
      (blur)="onTouchedCallback()"
    >
      <ng-container *ngIf="!showCustomOption">
        <mat-radio-button
          [disabled]="_disabled"
          class="c4p-radio-button"
          *ngFor="let option of options"
          [value]="option.value"
        >
          {{ option.label | translate }}
        </mat-radio-button>
      </ng-container>
      <ng-container *ngIf="showCustomOption">
        <mat-radio-button
          [disabled]="_disabled"
          class="c4p-radio-button"
          *ngFor="let option of options"
          [value]="option.value"
        >
          <div
            class="custom-option"
            [ngStyle]="{ 'background-color': option.value }"
          ></div>
        </mat-radio-button>
      </ng-container>
      <span *ngIf="required && !placeholder">*</span>
    </mat-radio-group>
    <span style="font-size: 85%" *ngIf="valueControl.touched">
      <mat-error *ngFor="let error of ngControl.errors | keyvalue">
        <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
          {{ errorLabels[error.key] | translate }}
        </span>
        <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
          <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        </ng-container>
      </mat-error>
    </span>
  </div>
</div>
