<div class="c4p-table-view-wrap">
  <div class="c4p-table-title-bar" *ngIf="options.showTitle != false">
    <span class="c4p-align-left">
      <span class="c4p-horizontal-divider"></span>
      <mat-icon *ngIf="options.titleIcon" [inline]="true" class="panel-icon">{{
        options.titleIcon
      }}</mat-icon>
      <span class="c4p-ellipsis" translate>{{ options.name }}</span>
      <span style="padding-left: 10px" *ngIf="options.showTotalCount">{{
        totalRows
      }}</span>
      <span class="c4p-horizontal-divider"></span>
      <div *ngIf="options.showSearch && showSearch" class="c4p-search">
        <mat-icon matPrefix class="c4p-search-icon">search</mat-icon>
        <input
          [readonly]="this.loading ? true : false"
          matInput
          [formControl]="searchControl"
        />
      </div>
    </span>
    <span class="c4p-align-right">
      <div
        *ngIf="options.showExport"
        class="c4p-button"
        (click)="onExport()"
        translate
      >
        Export
      </div>
      <div
        *ngIf="options.showLoadExisting"
        class="c4p-button"
        (click)="onLoadExisting()"
        translate
      >
        general.labels.LoadExisting
      </div>
      <div
        *ngIf="options.showAdd"
        class="c4p-button"
        (click)="onAdd()"
        translate
      >
        general.labels.Add
      </div>
      <div
        *ngIf="options.showProcess"
        class="c4p-button"
        (click)="onProcessGroups()"
        translate
      >
        billing.actions.ProcessGroup
      </div>
      <ng-content select="[panelButtons]"></ng-content>
      <span class="c4p-horizontal-divider"></span>
    </span>
  </div>

  <div class="c4p-table-title-bar" *ngIf="selectAllOption">
    <mat-checkbox
      class="c4p-align-left"
      style="padding-left: 20px; padding-top: 20px"
      (change)="onSelectAll($event.checked)"
    >
      {{ SELECT_ALL_KEY | translate }}
    </mat-checkbox>
  </div>

  <div class="c4p-table-title-bar" *ngIf="confirmationBar">
    <span class="c4p-align-left" style="padding-left: 20px">
      <p>{{ confirmationTitle | translate }}</p>
    </span>
    <span class="c4p-align-right">
      <div
        class="c4p-button"
        (click)="onConfirm()"
        click-stop-propagation
        translate
      >
        billing.actions.Confirm
      </div>
      <div
        class="c4p-button"
        (click)="onCancel()"
        click-stop-propagation
        translate
      >
        billing.actions.Cancel
      </div>
      <span class="c4p-horizontal-divider"></span>
    </span>
  </div>
  <mat-table [dataSource]="data" matSort (matSortChange)="handleSort($event)">
    <ng-container
      matColumnDef="{{ column.id }}"
      *ngFor="let column of options.columns; let rowIndex = index"
    >
      <!-- different presentation for different columnn type -->
      <!-- type date -->
      <div *ngIf="column.type == 'date'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 100px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">
            <ng-container *ngIf="column.format === 'date'">
              {{ element[column.id] | appDate }}
            </ng-container>
            <ng-container *ngIf="column.format === 'datetime'">
              {{ element[column.id] | appDateTime }}
            </ng-container>
            <ng-container *ngIf="column.format === 'tenantDate'">
              {{ element[column.id] | tenantDate }}
            </ng-container>
            <ng-container *ngIf="column.format === 'nullableDate'">
              {{ element[column.id] | tenantDate : "nullableDate" }}
            </ng-container>
          </span>
        </mat-cell>
      </div>
      <!-- type rich-text -->
      <div *ngIf="column.type == 'rich-text'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{
            element[column.id] | appRichText
          }}</span>
        </mat-cell>
      </div>

      <!-- type string -->
      <div *ngIf="column.type == 'string'">
        <mat-header-cell
          *matHeaderCellDef
          [disabled]="column.disableSorting"
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis" *ngIf="!column.format">{{
            element[column.id]
          }}</span>
          <span
            class="c4p-ellipsis"
            *ngIf="column.format === 'translate'"
            translate
            >{{ element[column.id] }}</span
          >
          <span class="c4p-ellipsis" *ngIf="column.format === 'title'">{{
            element[column.id]
          }}</span>
          <span
            class="c4p-ellipsis"
            *ngIf="column.format === 'source'"
            translate
            >{{
              'billing.labels.' +
                element[column.id].charAt(0).toUpperCase() +
                element[column.id].slice(1)
            }}</span
          >
          <span class="c4p-ellipsis" *ngIf="column.format === 'productcodes'">{{
            {
              internalProduct: element['internalProduct'],
              externalProduct: element['externalProduct']
            } | productCodes
          }}</span>
          <span class="c4p-ellipsis" *ngIf="column.format === 'invoiceType'">{{
            element[column.id] | billingAndInvoiceMethod
          }}</span>
          <span
            class="c4p-ellipsis-pre"
            *ngIf="column.format === 'tooltip'"
            matTooltip="{{ element[column.id] }}"
            >{{ element[column.id] }}</span
          >
        </mat-cell>
      </div>

      <!-- type progress -->
      <div *ngIf="column.type == 'progress'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div class="c4p-progress-back">
            <div
              class="c4p-progress-front"
              [ngStyle]="
                column.formatCompleteness &&
                column.formatCompleteness === 2 &&
                element[column.progress] === 0
                  ? { 'width.px': 60 }
                  : {
                      'width.px':
                        (element[column.id] / element[column.progress]) * 60
                    }
              "
            ></div>
            <div
              *ngIf="
                column.formatCompleteness === undefined &&
                element[column.progress] === 0
              "
              style="
                text-align: center;
                z-index: 10;
                margin: 4px;
                position: relative;
              "
            >
              {{ 'general.labels.Empty' | translate }}
            </div>
            <div
              *ngIf="
                element[column.id] !== element[column.progress] &&
                element[column.progress] !== 0
              "
              style="
                text-align: center;
                z-index: 10;
                margin: 4px;
                position: relative;
              "
            >
              {{ element[column.id] }} {{ 'general.labels.Of' | translate }}
              {{ element[column.progress] }}
            </div>
            <div
              *ngIf="
                (element[column.id] === element[column.progress] &&
                  element[column.progress] !== 0) ||
                (column.formatCompleteness &&
                  column.formatCompleteness === 2 &&
                  element[column.progress] === 0)
              "
              style="
                text-align: center;
                z-index: 10;
                margin: 4px;
                position: relative;
              "
            >
              {{ 'general.labels.Done' | translate }}
            </div>
          </div>
        </mat-cell>
      </div>

      <!-- type lookup -->
      <div *ngIf="column.type == 'lookup'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: left; flex: 1 0"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <mat-select
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
                style="vertical-align: sub"
                (click)="onEvent($event)"
              >
                <mat-option [value]="null" selected
                  >{{ 'general.labels.None' | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let option of column.filterValues"
                  [value]="option.value"
                  >{{ option.label | translate }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{
            column.lookupValues[element[column.id]] | translate
          }}</span>
        </mat-cell>
      </div>

      <!-- type object -->
      <div *ngIf="column.type == 'object'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{ column.name | translate }}</span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
          (click)= "column?.method(element, rowIndex);$event.stopPropagation()"
        >

          <span *ngIf="column.format !== 'badge'"
            class="c4p-ellipsis"
            matTooltip="{{
              element[column.id]
                ? element[column.id][column.objectProperty]
                : ''
            }}"
            >{{
              (element[column.id]
                ? element[column.id][column.objectProperty]
                : ''
              ) | translate
            }}</span
          >

          <span *ngIf="column.format === 'badge' && (!column.hideCondition || !column.hideCondition(element))">
            <div style="display: flex">
               <div class="self-icon">
                   <mat-icon [svgIcon]="'mySignature'+ element[column.id]['mySignatureColor']" [matTooltip]="element[column.id]['mySignatureToolTip']"></mat-icon>
               </div>
              <ng-container *ngIf="!element[column.id]['onlyMySignature']">
                 <div class="internal-icon" style="margin-left: 3px" *ngIf="element[column.id]['internalRatio']"
                     [matBadge]="element[column.id]['internalRatio']"
                     [matBadgeSize]="'small'"
                     matBadgePosition="below">
                   <mat-icon [svgIcon]="'internal'+ element[column.id]['internalColor']" [matTooltip]="element[column.id]['internalTooltip']" matTooltipClass="c4p-tooltip-preline">unsubscribe</mat-icon>
                </div>
                <div class="external-icon" style="margin-left: 3px" *ngIf="element[column.id]['externalRatio']"
                     [matBadge]="element[column.id]['externalRatio']"
                     [matBadgeSize]="'small'"
                     matBadgePosition="below"
                >
                   <mat-icon style="margin-left: 3px"
                             [svgIcon]="'external'+ element[column.id]['externalColor']"
                     [matTooltip]="element[column.id]['externalTooltip']" matTooltipClass="c4p-tooltip-preline">unsubscribe</mat-icon>
                </div>
              </ng-container>
            </div>
          </span>

        </mat-cell>
      </div>

      <!-- type list -->
      <div *ngIf="column.type == 'list'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <mat-select
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
                style="vertical-align: sub"
                (click)="onEvent($event)"
              >
                <mat-option [value]="null" selected
                  >{{ 'general.labels.None' | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let option of column.filterValues"
                  [value]="option.value"
                  >{{ option.label | translate }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis" matTooltip="{{ element[column.id] }}">{{
            element[column.id].join(', ')
          }}</span>
        </mat-cell>
      </div>

      <!-- type objectList -->
      <div *ngIf="column.type == 'objectList'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <mat-select
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
                style="vertical-align: sub"
                (click)="onEvent($event)"
              >
                <mat-option [value]="null" selected
                  >{{ 'general.labels.None' | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let option of column.filterValues"
                  [value]="option.value"
                  >{{ option.label | translate }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-font-normal c4p-ellipsis">
            <app-object-list
              (selectItem)="selectObjectListItem(element)"
              [data]="element[column.id]"
              [title]="column.name | translate"
              [objectProperty]="column.objectProperty"
            ></app-object-list>
          </span>
        </mat-cell>
      </div>

      <!-- type number -->
      <div *ngIf="column.type == 'number'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: right">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{
            (element[column.id] === null || element[column.id] === undefined) &&
            column.showNullValue
              ? element[column.id]
              : (element[column.id] | round)
          }}</span>
          <span style="width: 24px"></span>
        </mat-cell>
      </div>

      <!-- type toggle button -->
      <div *ngIf="column.type == 'toggleButton'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox (change)="toggleAll($event.checked)"></mat-checkbox>
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-slide-toggle
            style='margin-left: 0.8rem'
            *ngIf="(!column.hideCondition || !column.hideCondition(element))"
            [(ngModel)]="element[column.id]"
            (ngModelChange)="toggleSingle($event, element, column.id)"/>
        </mat-cell>
      </div>

      <!-- type color indicator -->
      <div *ngIf="column.type == 'colorIndicator'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: right">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span
            class="c4p-ellipsis color-indicator"
            [ngStyle]="{
              'background-color': getBackgroundColor(element[column.id])
            }"
            >{{
              (element[column.id] === null ||
                element[column.id] === undefined) &&
              column.showNullValue
                ? element[column.id]
                : (element[column.id] | number : '1.0-0' : 'nl') + column.format
            }}</span
          >
          <span style="width: 24px"></span>
        </mat-cell>
      </div>

      <!-- type currency -->
      <div *ngIf="column.type == 'currency'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: right">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: flex-end; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{
            element[column.id] ?? 0
              | currency : column.format : 'symbol' : '1.2-2' : 'nl'
          }}</span>
          <span style="width: 24px"></span>
        </mat-cell>
      </div>

      <!-- type status -->
      <div *ngIf="column.type == 'status'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span
            *ngIf='element[column.id]'
            class="c4p-status-icon c4p-status-icon-{{
              element[column.id]
            }} c4p-ellipsis"
            >{{ getTranslatedStatus(element,column) }}</span
          >
        </mat-cell>
      </div>
      <!-- type boolean -->
      <div *ngIf="column.type == 'boolean'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-icon
            matPrefix
            class="c4p-indicator-icon c4p-indicator-icon-{{
              element[column.id]
            }}"
            [matTooltip]="
              (element[column.id] === true
                ? 'general.labels.Yes'
                : element[column.id] === false
                ? 'general.labels.No'
                : 'general.labels.Unknown'
              ) | translate
            "
            >{{ column.format }}
          </mat-icon>
        </mat-cell>
      </div>

      <!-- type unread -->
      <div *ngIf="column.type == 'unread'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-icon
            matPrefix
            class="c4p-unread-application-indicator-icon"
            *ngIf="element[column.id]"
            [matTooltip]="'general.status.New' | translate"
            >{{ column.format }}
          </mat-icon>
        </mat-cell>
      </div>

      <!-- type checkbox -->
      <div *ngIf="column.type == 'checkbox'">
        <mat-header-cell
          [disabled]="column.disableSorting"
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: left; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox [disabled]='!checkData' *ngIf="column.format === 'allCheck'" (change)="allCheckRequest($event.checked,column.id)"></mat-checkbox>
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left;"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox
            *ngIf="(!column.hideCondition || !column.hideCondition(element)) && (column.format !== 'allCheck' && column.format !== 'rowSelect')"
            [disabled]="column.disabled"
            [ngModel]="element[column.id]"
            (ngModelChange)="element[column.id] = !element[column.id]"
          ></mat-checkbox>
          <mat-checkbox
            *ngIf="(!column.hideCondition || !column.hideCondition(element))  && (column.format === 'allCheck' || column.format === 'rowSelect')"
            [disabled]="column.disabled"
            [ngModel]="element[column.id]"
            (ngModelChange)="onCheckboxChange($event, element, column.id)"
          ></mat-checkbox>
        </mat-cell>
      </div>

      <!-- type avatar -->
      <div *ngIf="column.type == 'avatar'">
        <mat-header-cell
          *matHeaderCellDef
          style="justify-content: left; flex: 1 0 40px"
        >
          <span class="c4p-ellipsis">{{ column.name | translate }}</span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 40px"
        >
          <img
            *ngIf="element[column.id]"
            class="c4p-avatar-small"
            src="{{ element[column.id] }}"
            alt="avatar"
          />

          <ngx-avatars
            *ngIf="!element[column.id]"
            name="{{ element[options.avatarNameColumn] }}"
            size="32"
            bgColor="#808080"
          ></ngx-avatars>
        </mat-cell>
      </div>

      <div *ngIf="column.type == 'button'">
        <mat-header-cell
          *matHeaderCellDef
          style="justify-content: left; flex: 1 0 80px"
        >
          {{column.name | translate}}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 80px"
        >
          <div
            class="c4p-button"
            (click)="column.method(element, rowIndex); $event.stopPropagation()"
            translate
          >
            {{column.name | translate}}
          </div>
        </mat-cell>
      </div>

      <!-- type selection -->
      <div *ngIf="column.type == 'selection'">
        <mat-header-cell
          *matHeaderCellDef
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{ column.name | translate }}</span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox
            *ngIf="!column.hideCondition || !column.hideCondition(element)"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element.id) : null"
            [checked]="selection.isSelected(element.id)"
            [disabled]="column?.disabled"
          >
          </mat-checkbox>
        </mat-cell>
      </div>

      <!-- type selectionRow -->
      <div *ngIf="column.type == 'selectionRow'">
        <mat-header-cell
          *matHeaderCellDef
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{ column.name | translate }}</span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox
            *ngIf="!column.hideCondition || !column.hideCondition(element)"
            (click)="$event.stopPropagation()"
            (change)="$event ? selectionRow.toggle(element) : null"
            [checked]="isSelectedRow(element.id)"
          >
          </mat-checkbox>
        </mat-cell>
      </div>

      <!-- type selectionTwo -->
      <div *ngIf="column.type == 'selectionTwo'">
        <mat-header-cell
          *matHeaderCellDef
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="selection-cell"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <mat-checkbox
            *ngIf="!column.hideCondition || !column.hideCondition(element)"
            (click)="$event.stopPropagation()"
            (change)="$event ? selectionTwo.toggle(element.id) : null"
            [checked]="
              selectionTwo.isSelected(element.id) || !!element[column.id]
            "
          >
          </mat-checkbox>
        </mat-cell>
      </div>

      <!-- type function -->
      <div *ngIf="column.type == 'function'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis" translate
            >general.labels.{{ element[column.id] }}</span
          >
        </mat-cell>
      </div>

      <!-- type joinArray -->
      <div *ngIf="column.type == 'joinArray'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: left">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: left; flex: 1 0 180px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span class="c4p-ellipsis">{{
            element[column.id] | joinPipe : column.joinField
          }}</span>
        </mat-cell>
      </div>

      <div *ngIf="column.type == 'icon'">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngStyle]="{ 'min-width.px': 24 }">
          <ng-container *ngIf="element[column.id] === true">
            <mat-icon *ngIf="!column.hideCondition || !column.hideCondition(element)"
              [ngStyle]="{ color: 'column.iconColor' }"
              matPrefix
              class="c4p-action-icons"
              (click)="
                column.method(element, rowIndex); $event.stopPropagation()
              "
              [matTooltip]="column.iconTooltip | translate"
              >{{ column.format }}</mat-icon
            >
          </ng-container>
        </mat-cell>
      </div>

      <div *ngIf="column.type == 'iconWithHeader'">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header="{{ column.id }}"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <div style="text-align: center">
            <span class="c4p-ellipsis">{{ column.name | translate }}</span>
            <div
              *ngIf="showFilter"
              class="c4p-column-filter"
              [ngStyle]="{ 'width.px': column.width - 24 }"
            >
              <input
                matInput
                placeholder=""
                style="vertical-align: sub"
                (click)="onEvent($event)"
                [(ngModel)]="additionalFilters[column.id]"
                (ngModelChange)="onFilterChange(column.id, $event)"
              />
            </div>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          style="justify-content: center; flex: 1 0 80px"
          [ngStyle]="{ 'flex-basis.px': column.width }"
        >
          <span
            *ngIf='element[column.id]'
            class="c4p-action-icons-external"
          >  <mat-icon *ngIf="!column.hideCondition || !column.hideCondition(element)"
                       [ngStyle]="{ color: column.iconColor }"
                       matPrefix
                       class="c4p-action-icons-external"
                       (click)="column.method(element, rowIndex); $event.stopPropagation()"
                       [matTooltip]="column.iconTooltip | translate"
          >{{ column.format }}
            </mat-icon></span
          >
        </mat-cell>
      </div>

      <div *ngIf="column.type == 'externalIcon'">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngStyle]="{ 'min-width.px': 24 }">
          <ng-container *ngIf="element[column.id] === true">
            <mat-icon *ngIf="!column.hideCondition || !column.hideCondition(element)"
                      [ngStyle]="{ color: 'column.iconColor' }"
                      matPrefix
                      class="c4p-action-icons-external"
                      (click)="column.method(element, rowIndex); $event.stopPropagation()"
                      [matTooltip]="column.iconTooltip | translate"
            >{{ column.format }}
            </mat-icon>
          </ng-container>
        </mat-cell>
      </div>
    </ng-container>

    <!-- inline action icons -->
    <div *ngIf="menuActions">
      <ng-container matColumnDef="inline_action" stickyEnd>
        <mat-header-cell
          *matHeaderCellDef
          style="margin-top: 5px; justify-content: flex-end"
          [ngStyle]="{ 'minWidth.px': TOOLBAR_ICON_AMOUNT * 28 }"
        >
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let rowIndex = index"
          style="margin-top: 5px; justify-content: flex-end"
          [ngStyle]="{ 'minWidth.px': TOOLBAR_ICON_AMOUNT * 28 }"
        >
          <div *ngFor="let action of menuActions; let i = index">
            <ng-container *ngIf="action.includeBadgeNumber">
                <div
                *ngIf="i < TOOLBAR_ICON_AMOUNT"
                (click)="
                  action.method(element, rowIndex); $event.stopPropagation()
                "
                [class.disabled-inline-action]="
                  !action.hasPermission || !action.conditionMethod(element)
                "
              >
                <mat-icon
                  *ngIf="action.icon"
                  matPrefix
                  class="c4p-action-badge"
                  [matTooltip]="action.tooltip | translate"
                  matBadge="{{action.getBadgeNumber(element)}}"
                  aria-hidden='false'
                  >{{ action.icon }}</mat-icon
                >
              </div>
            </ng-container>
            <ng-container *ngIf="action.includeEntityForPermissionCalculation && !action.includeBadgeNumber">
              <div
                *ngIf="i < TOOLBAR_ICON_AMOUNT"
                (click)="
                  action.method(element, rowIndex); $event.stopPropagation()
                "
                [class.disabled-inline-action]="
                  !action.entityPermissionMethod(element)
                "
              >
                <mat-icon
                  *ngIf="action.icon"
                  matPrefix
                  class="c4p-action-icons"
                  [matTooltip]="action.tooltip | translate"
                  >{{ action.icon }}</mat-icon
                >
              </div>
            </ng-container>
            <ng-container *ngIf="!action.includeEntityForPermissionCalculation && !action.includeBadgeNumber">
              <div
                *ngIf="i < TOOLBAR_ICON_AMOUNT"
                (click)="
                  action.method(element, rowIndex); $event.stopPropagation()
                "
                [class.disabled-inline-action]="
                  !action.hasPermission || !action.conditionMethod(element)
                "
              >
                <mat-icon
                  *ngIf="action.icon"
                  matPrefix
                  class="c4p-action-icons"
                  [matTooltip]="action.tooltip | translate"
                  >{{ action.icon }}</mat-icon
                >
              </div>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>
    </div>

    <!-- menu -->
    <div *ngIf="menuActions.length > TOOLBAR_ICON_AMOUNT">
      <ng-container matColumnDef="menu_action" stickyEnd>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let rowIndex = index"
          (click)="$event.stopPropagation()"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            *ngIf="isMenuVisible(element, rowIndex)"
            [matMenuTriggerData]="{ name: element, rowIndex: rowIndex }"
          >
            <mat-icon class="c4p-action-icons">more_horiz</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    </div>

    <div *ngIf="options.showHeader">
      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        [ngStyle]="{ 'min-width.px': minWidth }"
      ></mat-header-row>
    </div>

    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      [ngClass]="{
        highlighted: highlighted[i],
        'row-selection': true,
        'highlighted-non-selection': highlightedNonSelected[i]
      }"
      [ngStyle]="{ 'min-width.px': minWidth }"
      (click)="onClick(row, i)"
      (dblclick)="onDoubleClick(row, i)"
    ></mat-row>
  </mat-table>

  <div *ngIf="emptyCheck" class='empty-table'>
    <div class='empty-table-on'>
      <ng-content select="[text]"></ng-content>
    </div>
  </div>
  <mat-paginator
    *ngIf="options.showPagination && !emptyCheck"
    [length]="totalRows"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="handlePage($event)"
  >
  </mat-paginator>
</div>

<mat-menu #menu="matMenu" class="c4p-menu">
  <ng-template matMenuContent let-name="name" let-rowIndex="rowIndex">
    <ng-container *ngIf="menuActions.length > TOOLBAR_ICON_AMOUNT">
      <div
        class="table-menu-action-container"
        *ngFor="let action of menuActions; let i = index"
      >
        <ng-container *ngIf="i >= TOOLBAR_ICON_AMOUNT">
          <ng-container *ngIf="action.includeEntityForPermissionCalculation">
            <div
              [disabled]="!action.entityPermissionMethod(name)"
              mat-menu-item
              class="c4p-menu-item"
              (click)="action.method(name, rowIndex)"
            >
              <mat-icon *ngIf="action.icon" class="c4p-action-menu-icon">{{
                action.icon
              }}</mat-icon>
              <span class="c4p-menu-title">{{
                action.tooltip | translate
              }}</span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !action.includeEntityForPermissionCalculation &&
              action.visibleConditionMethod
            "
          >
            <div
              [class.disabled]="!action.hasPermission || !action.conditionMethod(name)"
              *ngIf="action.visibleConditionMethod(name)"
              mat-menu-item
              class="c4p-menu-item"
              (click)="action.method(name, rowIndex)"
            >
              <mat-icon *ngIf="action.icon" class="c4p-action-menu-icon">{{
                action.icon
              }}</mat-icon>
              <span class="c4p-menu-title">{{
                action.tooltip | translate
              }}</span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !action.includeEntityForPermissionCalculation &&
              !action.visibleConditionMethod
            "
          >
            <div
              [class.disabled]="!action.hasPermission || !action.conditionMethod(name)"
              mat-menu-item
              class="c4p-menu-item"
              (click)="action.method(name, rowIndex)"
            >
              <mat-icon *ngIf="action.icon" class="c4p-action-menu-icon">{{
                action.icon
              }}</mat-icon>
              <span class="c4p-menu-title">{{
                action.tooltip | translate
              }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
