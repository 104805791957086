import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ActionType, NotificationDTO, PaginationDataModel, PaginationResultModel, SideSheetButtonEvent, SideSheetButtonListener } from '../../models';
import { NotificationService, SideSheetService, UserInfoService } from '../../services';
import {PageEvent} from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '../../utilities';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements SideSheetButtonListener, OnInit {

  @ViewChild('sidesheetContentFooterTemplate', { static: true }) sidesheetContentFooterTemplate: TemplateRef<any>;
  @ViewChild('sidesheetContentHeaderTemplate', { static: true }) sidesheetContentHeaderTemplate: TemplateRef<any>;

  private readonly filterParamsChangedSubject = new Subject<any>();
  private readonly destroyed = new Subject<boolean>();

  showFilterPanel = false;
  selectedNotification = null;
  paginationData = new PaginationDataModel(
    'createdAt',
    'desc',
    10
  );

  pageSizeOptions = [5, 10, 20, 50, 100];
  page: PaginationResultModel<NotificationDTO> = null;

  filterParams = {
    unreadSelected: false,
    starredSelected: false,
    search: '',
    fromDate: null,
    untilDate: null
  };

  dateTimeFormat = 'short';
  markAllTooltipOpen: boolean=false;
  constructor(
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userInfoService: UserInfoService,
    private sideSheetService: SideSheetService
  ) {
  }

  ngOnInit(): void {
    this.filterParamsChangedSubject.pipe(takeUntil(this.destroyed), debounceTime(500))
    .subscribe((filter) => {
      this.paginationData.pageIndex = 0;
      this.loadNotifications(filter);
    });

    this.loadNotifications(this.filterParams);
  }

  ngOnDestroy(): void {
    this.notificationService.updateUnreadMessageCount(this.userInfoService?.userId);
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  onToggleNotificationContent(notification: NotificationDTO, readUnreadFlag?: boolean) {
    if (this.selectedNotification?.id === notification?.id && !readUnreadFlag) {
      this.selectedNotification = null;
      this.loadNotifications(this.filterParams);
    } else {
      if (!notification.currentUserRead || readUnreadFlag) {
        this.readNotifications([notification], true, false);
      }

      this.selectedNotification = notification;
    }
  }

  onReadUnreadFlag(notification: NotificationDTO) {
    this.selectedNotification = null;
    this.readNotifications([notification], !notification.currentUserRead, true);
  }

  onClickButton(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      this.sideSheetService.closeSideSheet();
    }
  }

  onMarkAllAsRead($event) {
    console.log($event.readAll)
    this.readNotifications(this?.page?.docs, true, true, $event.readAll);
  }

  onCloseMarkAllAsReadTooltip(event) {
    this.markAllTooltipOpen = false;
  }

  onOpenMarkAllAsReadTooltip(event:any){
    this.markAllTooltipOpen = true
    event.stopPropagation();
  }

  onToggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
  }

  onChangeStar(notification: NotificationDTO, star: boolean) {
    this.notificationService.starNotification(notification?.id, star).subscribe(data => {
        Object.assign(notification, data);
        if(this.filterParams?.starredSelected) {
          this.loadNotifications(this.filterParams);
        }
    });
  }

  onFilterParamsChanged(event) {
    this.filterParamsChangedSubject.next(this.filterParams);
  }

  handlePage($event: PageEvent) {
    this.paginationData.pageIndex= (this.paginationData.pageSize !== $event.pageSize ? 0 : $event.pageIndex);
    this.paginationData.pageSize = $event.pageSize;
    this.loadNotifications(this.filterParams);
  }

  loadNotifications(filter) {
    const tempFilter = {...filter};

    tempFilter.fromDate = DateUtils.changeDateToBrowserTimezone(tempFilter.fromDate);
    tempFilter.untilDate = DateUtils.changeDateToBrowserTimezone(tempFilter.untilDate);

    this.notificationService.
      getNotifications(this.paginationData, tempFilter)
      .subscribe((data: PaginationResultModel<NotificationDTO>) => {
        this.page = data;
      });
  }

  readNotifications(notifications: NotificationDTO[], readFlag: boolean, loadNotificationIfNeeded: boolean, readAll: boolean= false) {
    if (notifications?.length > 0) {
      const notificationIds = notifications?.map(notification => notification?.id);

      this.notificationService.readNotifications(notificationIds, readFlag, readAll).subscribe(data => {
        if(this.filterParams?.unreadSelected && loadNotificationIfNeeded) {
          this.loadNotifications(this.filterParams);
        } else {
          notifications.forEach(n => {
            n.currentUserRead = readFlag;
          });
        }
        this.markAllTooltipOpen = false;
      });
    }
  }


}
