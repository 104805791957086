import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { C4pUtilModule } from '../../c4p-portal-util.module';
import { MaterialModule } from '../../material.module';
import { TranslateModule } from '@ngx-translate/core';

const moduleComponents = [NotificationSettingsComponent];

@NgModule({
  declarations: [...moduleComponents],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    C4pUtilModule,
    TranslateModule,
    MaterialModule,
  ],
  exports: [...moduleComponents],
})
export class UserSettingsModule {}
