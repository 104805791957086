import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import {NotificationService, PermissionService, SideSheetService} from '../../services';
import { SideSheetBackdropType, SideSheetSize} from '../../models';
import {NotificationComponent} from '../notification/notification.component';
import { NavbarMode } from '../navbar/navbar.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  @Input() navBarMode!: NavbarMode;
  @Output() navbarModeChanged = new EventEmitter<NavbarMode>();

  public hasNotificationPermission: boolean;

  get count$(): BehaviorSubject<number> {
    return this.notificationService.count$;
  }


  constructor(
    public router: Router,
    private permissionsService: PermissionService,
    private sideSheetService: SideSheetService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.hasNotificationPermission = this.permissionsService.getPermission(
      'notification:manage',
      'or',
    );
  }


  onNavBarOpenClicked(event: any) {
    this.navbarModeChanged.emit(NavbarMode.OPENED);
  }

  onToggleNotification() {
    const sideSheetRef = this.sideSheetService.loadComponent(
      NotificationComponent,
      {title: 'notification.titles.Notifications'},
      {
        size: SideSheetSize.MD,
        commandLineVisible: false,
        breadcrumbFullscreenVisible: true,
        disableCloseOnBackdropClick: false
      },
      true,
    );

    sideSheetRef.afterClosed().subscribe((hours) => {
      //
    });
  }


  public onRoleChanged() {
    this.hasNotificationPermission = this.permissionsService.getPermission(
      'notification:manage',
      'or',
    );
  }

  public onSearch(data) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/search', data]));
  }
}
