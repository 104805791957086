import {Component, Inject, OnInit} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfigurationModel } from '../../../models/fcm-message/configuration.model';
import { NotificationCategoryModel } from '../../../models/fcm-message/notification-category.model';
import { UserInfoService } from '../../../services';
import { NotificationSettingsService } from '../../services/notification-settings.service';
@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css'],
})
export class NotificationSettingsComponent implements OnInit{
  private selectedSubjects = new Subject<string[]>();
  private readonly destroyed$ = new Subject<boolean>();
  public loading: boolean;
  form: FormGroup = null!;
  subsData: NotificationCategoryModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<NotificationSettingsComponent>,
    private notificationSettingsService: NotificationSettingsService,
    public userInfoService: UserInfoService,
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm();
    this.notificationSettingsService
        .getNotificationSubjects()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          this.subsData = data;
          this.addCheckboxes();
        });
  }
  buildForm(){
    return this.formBuilder.group({
      subjects: new FormArray([]),
    });
  }
  getSelectedSubjects(): Observable<string[]> {
    this.notificationSettingsService
      .getConfigurationsByUserId(this.userInfoService.userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((configuration: ConfigurationModel) => {
        if (configuration && configuration.notificationCategories) {
          let selectedSubjects = [];
          for (let subject of configuration.notificationCategories) {
            selectedSubjects.push(subject.name);
          }
          this.selectedSubjects.next(selectedSubjects);
        }
      });
    return this.selectedSubjects.asObservable();
  }

  get subsFormArray() {
    return this.form.controls.subjects as FormArray;
  }

  private addCheckboxes() {
    this.loading = true;
    this.getSelectedSubjects()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selected) => {
        this.subsData.forEach((x: NotificationCategoryModel) =>
          this.subsFormArray.push(
            new FormControl(selected.includes(x.name) ? true : false),
          ),
        );
        this.loading = false;
      });
  }

  public onConfirm() {
    const selectedSubs: Array<string> = this.form.value.subjects
      .map((checked, i) => (checked ? this.subsData[i].name : null))
      .filter((c) => c !== null);
    let configurationSubs: ConfigurationModel = {
      userId: this.userInfoService.userId,
      notificationCategories: [],
    };
    selectedSubs.forEach((subject) => {
      configurationSubs.notificationCategories.push({ name: subject });
    });
    this.notificationSettingsService
      .updateConfiguration(configurationSubs)
      .pipe(take(1))
      .subscribe();
    this.dialogRef.close(null);
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}
