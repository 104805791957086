<div class="c4p-form-title c4p-flex-title" [title]="title | translate">
  <span class="c4p-align-left">
    <span class="c4p-horizontal-divider"></span>
    <mat-icon *ngIf="titleIcon" [inline]="true" class="panel-icon">{{
      titleIcon
      }}</mat-icon>
    <span [ngClass]="{'aligned-with-icon': !isCustomTitleClassActive,'aligned-with-icon custom-title-class': isCustomTitleClassActive }"  *ngIf="title">
      {{ title | translate }}
    </span>
      <ng-container *ngIf="status">
          <span [ngClass]="{'c4p-badge-active': isActive,'c4p-badge-passive': !isActive }">
    {{(isActive ? 'client.titles.Active' : 'client.titles.Inactive') | translate}}
  </span>
  </ng-container>
  </span>
  <span class="c4p-align-right" translate>
    <ng-content select="[panelButtons]"></ng-content>
    <ng-content select="[panelIconButtons]"></ng-content>
    <span class="c4p-horizontal-divider"></span>
  </span>
</div>
