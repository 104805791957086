<app-panel type="popup">
  <app-panel-title title="user-settings.titles.notification-configuration">
  </app-panel-title>

  <div [formGroup]="form">
    <app-panel-form-row
      formArrayName="subjects"
      *ngFor="let sub of subsFormArray.controls; let i = index"
    >
      <ng-container [formGroupName]="i">
        <mat-checkbox [formControl]="sub">{{
          'notification.titles.' + subsData[i].name | translate
        }}</mat-checkbox>
      </ng-container>
    </app-panel-form-row>
  </div>

  <div *ngIf="loading" class="spinner-item">
    <mat-progress-spinner
      [mode]="'indeterminate'"
      [diameter]="25"
    ></mat-progress-spinner>
  </div>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()">
      <span>OK</span>
    </span>
  </div>
</app-panel>
